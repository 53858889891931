import * as React from "react";
import { Image, Item, Icon, IconProps, ItemContent } from "semantic-ui-react";

interface CybozuQAInfo {
  self:boolean; // true: open self page, false: open new page
  image: string;
  url: string;
  header: string;
  icon: IconProps["name"];
}

const CybozuQAInformations: CybozuQAInfo[] = 
  [
      {
        self: true,
        image: "/images/Cybozu-QA/qrcode_CybozuQA.png",
        url: "https://tech.cybozu.io/Cybozu-QA/",
        header: "このページ",
        icon: "linkify",
      },
      {
        self: false,
        image: "/images/Cybozu-QA/qrcode_Blog-QA.png",
        url: "https://blog.cybozu.io/archive/category/QA",
        header: "Cybozu Inside Out (Blog) - Category: QA",
        icon: "linkify",
      },
      {
        self: false,
        image: "/images/Cybozu-QA/qrcode_Blog-test.png",
        url: "https://blog.cybozu.io/archive/category/%E3%83%86%E3%82%B9%E3%83%88",
        header: "Cybozu Inside Out (Blog) - Category: テスト",
        icon: "linkify",
      },
      {
        self: true,
        image: "/images/Cybozu-QA/qrcode_Slides-QA.png",
        url: "https://tech.cybozu.io/slides/tags/qa/",
        header: "CybozuTech (Portal Site) - Slides:QA",
        icon: "linkify",
      },
    ];
  


const CybozuQAList: React.FC = () => {
  return ( 
    <>
      {/* Cybozu QAの情報 */}
      {CybozuQAInformations.map((info) => {
        return (
          <>
          <Item className="nolink-item">
            <Item.Content>
              {info.self ? <Item.Header href={info.url}>{info.header}</Item.Header> : 
              <Item.Header href={info.url} target="_blank" rel="noreferrer noopener">{info.header}</Item.Header>}
              <Item.Description>
                <Image src={info.image} size='tiny' verticalAlign='middle' /> 
                <Icon name={info.icon} />{info.self ? <Item.Content verticalAlign='middle' href={info.url}>{info.url}</Item.Content> :
                 <Item.Content verticalAlign='middle' href={info.url} target="_blank" rel="noreferrer noopener">{info.url}</Item.Content>}
              </Item.Description>
            </Item.Content>
          </Item>
          </>
            )})}
      </>
  );
}

export default CybozuQAList;
