import * as React from "react";
import { Image, Item, Icon, IconProps, ItemContent } from "semantic-ui-react";

interface CybozuA11YInfo {
  self:boolean; // true: open self page, false: open new page
  image: string;
  url: string;
  header: string;
  icon: IconProps["name"];
}

const CybozuA11YInformations: CybozuA11YInfo[] = 
  [
      {
        self: false,
        image: "/images/Cybozu-QA/qrcode_Blog-Accessibility.png",
        url: "https://blog.cybozu.io/archive/category/%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B7%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3",
        header: "Cybozu Inside Out (Blog) - Category: アクセシビリティ",
        icon: "linkify",
      }
    ];
  


const CybozuA11YList: React.FC = () => {
  return ( 
    <>
      {/* Cybozu A11Yの情報 */}
      {CybozuA11YInformations.map((info) => {
        return (
          <>
          <Item className="nolink-item">
            <Item.Content>
              {info.self ? <Item.Header href={info.url}>{info.header}</Item.Header> : 
              <Item.Header href={info.url} target="_blank" rel="noreferrer noopener">{info.header}</Item.Header>}
              <Item.Description>
                <Image src={info.image} size='tiny' verticalAlign='middle' /> 
                <Icon name={info.icon} />{info.self ? <Item.Content verticalAlign='middle' href={info.url}>{info.url}</Item.Content> :
                 <Item.Content verticalAlign='middle' href={info.url} target="_blank" rel="noreferrer noopener">{info.url}</Item.Content>}
              </Item.Description>
            </Item.Content>
          </Item>
          </>
            )})}
      </>
  );
}

export default CybozuA11YList;
