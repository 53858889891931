import * as React from "react";
import { IconProps, Image, Item } from "semantic-ui-react";
import XIcon from "./XIcon";

interface OtherInfo {
  qrcode: string;
  link: string;
  header: string;
  icon: JSX.Element;
}

const OtherInformations: OtherInfo[] =
[{    
      qrcode: "/images/Cybozu-QA/qrcode_X-InsideOut.png",
      link: "https://x.com/cybozuinsideout",
      header: "X（旧 Twitter）@CybozuInsideOut",
      icon: <XIcon />,  
}];

const QAOthersList: React.FC = () => {
  return ( 
    <>
      {/* その他の情報 */}
      {OtherInformations.map((info) => {
        return (
          <>
          <Item className="nolink-item">
            <Item.Content>
              <Item.Header href={info.link} target="_blank" rel="noreferrer noopener">{info.header}</Item.Header>
              <Item.Description>
                <Image src={info.qrcode} size='tiny' verticalAlign='middle' /> 
                {info.icon}<Item.Content verticalAlign='middle' href={info.link} target="_blank" rel="noreferrer noopener" className="garoon-dev-content">{info.link}</Item.Content>
              </Item.Description>
            </Item.Content>
          </Item>
          </>
            )})}
      </>
  );
}

export default QAOthersList;
