import * as React from "react";
import { Header, Segment } from "semantic-ui-react";

interface TitleProps {
  title: string;
  noHeader?: boolean;
}

const Title: React.FC<TitleProps> = ({ title, noHeader }) => {
  if (noHeader) {
    return null
  }

  return (
    <Segment vertical padded>
      <Header as="h1">
        <Header.Content>
          {title}
        </Header.Content>
      </Header>
    </Segment>
  );
};

export default Title;

